import styled from 'styled-components';
const BoldFont = "Rubik-Bold";
const RegularFont = "Rubik-Regular"
import img from '/public/images/Rewards/rewardsBg.png';
const Layout = styled.div`
  width: 100%;
  @media (max-width: 600px){
    padding: 0px;
  }
`;

const TitleFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 10px;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 16px;
      text-transform: uppercase;
    }
    >img{
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
  }
  > div: last-child{
    > div{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }
    > div: last-child{
      font-size: 16px;
    }
  }
`;
const ProgressTitle = styled.div`
  font-size: 13px;
  letter-spacing: 0px;
  color: #26262B;
  font-family: ${BoldFont};
  opacity: 0.7;
  margin-top: 5px;
  display: block;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dedcdc;
`;

const ProgressCard = styled.div`
  width: 100%;
  height: 250px;
  box-shadow: 0 0 21px rgba(0,0,0,0.08);
  background-color: #ffffff;
  margin: 10px 0px;
  @media (max-width: 330px) {
    height: 220px;
  }
  .padding {
    padding: 15px;
  }
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > div {
    display: block;
    text-align: center;
  }
`;

const RewardLevel = styled.div`
  width: calc(100%/4);
  float: left;
  background-color: ${({color}) => color && color};
  height: 38px;
  display: flex;
flex-wrap: wrap;
   border-top-left-radius:  ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
   border-bottom-left-radius: ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
  border-top-right-radius:  ${({borderRightRadius}) => borderRightRadius && '20px'} ;
 border-bottom-right-radius: ${({borderRightRadius}) => borderRightRadius && '20px'} ;
  position: relative;
  z-index:  ${({zIndex}) => zIndex && zIndex};
`;

const ArrowContainer = styled.div`
  height: 41px;
  width: 41px;
  transform: rotate(45deg);
  margin-left: -22px; 
  padding: 5px
  z-index: 0;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  float: left;
`;

const ArrowReward = styled.div`
  height: 32px;
  width: 32px;
  background-color: ${({bgColor}) => bgColor && bgColor};
  border-radius: 3px;
`;

const RewardText = styled.div`
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 100%;
  .title {
    position: relative;
    top: -48px;
    color: black;
    right: -51px;
    font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
    .image_cls 
    {
  > img {
    width: auto;
    height: 80px;
    margin: 10px 10px 0 0;
    @media(max-width: 500px){
      width: 20px;
      height: 21px;
      margin: 2px 2px 0 0;
    }
    @media(max-width: 440px){
      width: 16px;
    }
  }
}
  .bronze_pointValue {
    position: absolute;
    padding-top: 111px;
    padding-left: 56px;
    color: black;
    font-family: 'Rubik';
    text-transform: lowercase;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #A57576;

  }
  .pointValue {
    position: absolute;
    padding-top: 111px;
    padding-left: 47px;
    color: black;
    font-family: 'Rubik';
    text-transform: lowercase;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #A57576;
  }
  .text {
    font-family: ${BoldFont};
    text-transform: capitalize;
    font-size: 14px;
    @media(max-width: 440px){
      font-size: 13px;
    }
  }
  .levelPoint {
    width: 100%;
    text-align: right;
    text-transform: lowercase;
    font-family: ${RegularFont}
    margin-right: 2px;
    @media(max-width: 600px){
      text-align: center;
    }
    @media(max-width: 340px){
      font-size: 7px;
    }
  }

  @media (max-width: 1000px){
    font-size: 9px;
  }
  .details{
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 600px){
      flex-direction: column;
    }
  }
`;

const ProgressContent = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  .imageContainer {
    display: inline-block;
    padding-top: 8px;
    float: left;
    width: 8%;
    padding-right: 5px;
    >img {
      width: 56px;
      height: 70px;
    }
    @media(max-width: 600px) {
      width: 15%;
    }
  }
  .buttonWrapper {
    display: inline-block;
    width: 30%;
    text-align: right;
    @media(max-width: 600px) {
      width: 100%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ProgressPoints = styled.div`
  display: inline-block;
  padding-left: ${({padding}) => padding ? '0px' : '13px'};
  width: auto;
  @media(max-width: 600px) {
    margin-left: 3px;
  }
  .points {
    font-size: 30px;
    color: ${({padding}) => padding ? '#FFFFFF' : '#484856'};
    font-family: ${RegularFont};
    font-weight: 800;
    white-space: nowrap;
  }
  .totalPoint {
    font-size: 18px;
    color: ${({padding}) => padding ? '#FFFFFF' : '#484856'};
    font-family: ${RegularFont};
    font-weight: 800;
    text-transform: capitalize;
  }
  .pointContent {
    font-size: 14px;
    letter-spacing: 0px;
    color: #484856;
    font-family: ${RegularFont};
    margin-top: 4px;
    display: flex;
    white-space: nowrap;
  }
  .inline {
    display: inline-block;
    >span{
      font-family: ${BoldFont};
      text-transform: capitalize;
    }
  }
`;

const ProgressBar = styled.div`
width: 100%;
height: 38px;
background: #F3F3F5;
border-radius: 18px;
margin-top: 12px;
display: flex;
flex-wrap: wrap;
  .progrss {
    display: flex;
    height: 38px;
    border-radius: 18px;
    background-color: ${({color}) => color && color};
    width: ${({width}) => width && `${width}%`};
  }

`;

const ImageBar = styled.div`
 width: 100%;
 .image_cls {
  width: ${({width}) => width ?  width: width};
  height: ${({height}) => height ? height : height};
  position: absolute;
  top: 58px;
  left: 38%;
  div{
    >img {
 width: 100%;
height:  100%;
}

  }
>img {
 width: 100%;
height:  100%;
}
}
.silver_img {
width: ${({width}) => width ?  width: width};
  height: ${({height}) => height ? height : height};
  position: absolute;
  top: 58px;
  left: 55%;
  >img {
    width: 100%;
   height:  100%;
  }
}
.gold_img {
  position: absolute;
width: ${({width}) => width ?  width: width};
height: ${({height}) => height ? height : height};
  top: 58px;
  left: 75%;
  div{
    >img {
    width: 100%;
   height:  100%;
  }

  }
  >img {
    width: 100%;
   height:  100%;
  }
}
.flag_img {
position: relative;
height: 124px;
padding-left: calc(2% - 0px);
top: -448px; // -438px;

width: 73.22px; // 59.22px;
height: 65px;
// position: absolute;
// top: 205px;
left: 93.5%; // 89.8%;
div{
  >img {
  width: 100%;
 height:  100%;
}

}
>img {
  width: 100%;
 height:  100%;
}
}

`;

const BarContainer = styled.div`
  height: 100%;
  width: ${({newWith}) => newWith ? '57%' : '100%'};
  background-color: ${({color}) => color && color};
  border-top-left-radius:  ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
  border-bottom-left-radius: ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
  border-top-right-radius:  ${({borderRightRadius}) => borderRightRadius && '20px'} ;
  border-bottom-right-radius: ${({borderRightRadius}) => borderRightRadius && '20px'} ;
  .flexBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    @media(max-width: 440px){
      width: auto;
    }
  }
  @media(max-width: 700px) {
    width: ${({ customAlign}) => customAlign ? '82%': '91%'};
    margin-left: ${({ customAlign}) => customAlign ? '16%': '6%'};
  }
`;

const RewardsCategoriesTitle = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  font-family: ${BoldFont};
  padding-top: 20px;
  margin-bottom: 15px;
  width: 100%;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media(max-width: 600px) {
    margin: 0px;
    display: block;
  }
`;

const RewardsDashboardContainer = styled.div`
  width: 100%;
  padding: 10px;
`;
const MainContainer = styled.div`
  width: 77%;
  float: left;
  padding: 5px 10px 5px 0px;
  @media(max-width: 1200px){
    width: 100%;
  }
`;
const SideSection = styled.div`
  width: calc(23% - 10px);
  float: left;
  margin-right: 10px
  @media(max-width: 1200px){
    width: 60%;
  }
  @media(max-width: 500px){
    width:100%;
  }
`;
const CategoryCardV2 = styled.div`
  width: calc((100% / 4) - 7px);
  border-radius: 6px;
  display: flex;
  margin: 10px 7px 10px 0;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  float: left;
  flex-wrap: wrap;
  &:last-child{
    margin: 10px 0;
    width: calc(100% / 4);
  }
  .bg-opacity {
    position: absolute;
    height: 180px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    border-radius: 6px;
  }
  .information-icon{
    position: absolute;
    top: 15px;
    right: 22px;
    >img{
     width: 26px;
     height: 26px;
    }
  }
  .textContainer {
    position: absolute;
    top: 55px;
    left: 10px;
    padding: 10px;
    float: left;
    width: 100%;
    .rewardName {
      font-size: 18px;
      letter-spacing: 0px;
      color: #FFFFFF;
      font-family: ${RegularFont};
      margin-bottom: 20px;
    }
    .available {
      font-size: 13px;
      letter-spacing: 0px;
      color: #FFFFFF;
      font-family: ${RegularFont};
      margin-top: -3px;
      > div {
        display: inline-block;
      }
    }
  }
  .pointsCalculator{
    width: 100%;
    background: #185873;
    box-shadow: 0px 3.28141px 6.56281px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 10px 0;
    .pointsHeader{
      padding: 10px 7px;
      font-size: 9px;
      font-family: ${BoldFont};
      line-height: 8px;
      color: #FFFFFF;
    }
    .pointsEarned{
      width: 100%;
      background: #1F6C8D;
      box-shadow: 0px 3.28141px 6.56281px rgba(0, 0, 0, 0.08);
      padding: 6px;
      border-radius: 6px;
      .earnedText{
        padding: 7px;
        font-size: 11px;
        font-family: ${BoldFont};
        line-height: 8px;
        color: #FFFFFF;
      }
    }
  }
  .bonusPoints{
    width: 100%;
    background: #1F6C8D;
    box-shadow: 0px 3.28141px 6.56281px rgba(0, 0, 0, 0.08);
    padding: 6px;
    border-radius: 6px;
    .bonusHeader{
      padding: 7px;
      font-size: 11px;
      font-family: ${BoldFont};
      line-height: 8px;
      color: #FFFFFF;
    }
  }
  @media (max-width: 1200px){
    width: calc((100% / 3) - 7px);
    &:last-child{
      width: calc(100% / 3);
    }
  }
  @media (max-width: 1000px){
    width: calc((100% / 2) - 7px);
    &:last-child{
      width: calc(100% / 2);
    }
  }
  @media (max-width: 600px){
    width: 100%;
    &:last-child{
      width: 100%;
    }
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  display: inline-block;
  position:relative
  height: 180px;
  border-radius: 6px;
  > img {
    height: 180px;
    border-radius: 6px;
    width: 100%;
  }
`;
const PointsCard = styled.div`
  padding: 5px;
  background: #FFFFFF;
  margin: 0px 0px 6px;
  border-radius: 4px;
  box-shadow: 0px 3.93769px 6.56281px rgba(0, 0, 0, 0.08);
  &:last-child{
    margin: 0;
  }
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 20px;
    }
    >img{
      width: ${({imageWidth}) => imageWidth ? imageWidth : '20px'};
      height: 20px;
    }
  }
  >div:last-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div: first-child{
      >div{
        font-family: ${RegularFont};
        font-size: 10px;
        color: #484856;
        line-height: 10px;
      }
      >div: first-child{
        text-transform: uppercase;
        font-size: 10px;
        padding-bottom: 3px;
      }
      >span{
        font-size: 10px;
        color: #4798D9;
        cursor: pointer;
        border-bottom: 1px solid #4798D9;
      }
    }
    >div: last-child{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 20px;
      >span{
        font-size: 14px;
      }
    }
  }
`;
const MyHistory = styled.div`
  width: 100%;
  background: #FFFFFF;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #1E76AB;
    padding: 10px;
    border-radius: 6px 6px 0 0;
    >div{
      color: #FFFFFF;
      font-family: ${BoldFont};
      font-size: 14px;
      text-transform: uppercase;
    }
    >img{
      width: 20px;
      height: 25px;
      margin-right: 8px;
    }
  }
  > div: last-child{
    >div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      > div: first-child{
        display: flex;
        align-items: center;
        >img{
          width: 37px;
          height: 50px;
          margin-right: 8px;
        }
        >div{
          .yearText{
            color: #282C37;
            font-family: ${RegularFont};
            text-transform: capitalize;
            font-size: 17px;
          }
          .year{
            color: #282C37;
            font-family: ${BoldFont};
            font-size: 16px;
            opacity: 0.5;
          }
        }
      }
      > div: last-child{
        color: #000000;
        font-family: ${BoldFont};
        font-size: 16px;
      }
    }
  }
`;
const RightArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 21px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 22px solid #DCC34C;
  position: absolute;
  top: 0;
  right: -22px;
`;

const NewProgressCard = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  background-color: #ffffff;
  margin: 10px 0px;
  @media (max-width: 330px) {
    height: 220px;
  }
  .padding {
    padding: 15px;
  }
`;
const NewProgressContent = styled.div`
    width: 33.4%;
    height: 180px;
    background-color: #0D4270;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    .main_content {
    display: flex;
    padding-left: 19px;
    .newImageData { 
     .child_image {
      width: 84px;
      height: 114px;
    position: absolute;
    margin-left: 12px;
    margin-top: 32px
      >img {
          width: 100%;
          height:114px
      }
     }

    

    }
.main_point_class 
{
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 18px;
.point_class {
    .total_value {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 27px;
        color: #FFFFFF;
    }
    .total_point_value {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    color: #FD7175;
    }
    .left_point {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #FFFFFF;
        .inline {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;

        .point {
          font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #FFFFFF;
        }

        .point_reach {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 26px;
          color: rgba(255, 255, 255, 0.9);
        }

        .level {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          text-transform: capitalize;
          color: #FFFFFF;
        }
        }
   .earn_point {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.9);
   }
    }
    .border_line {
      width: 210px;
      height: 0px;
      border: 1px solid rgba(255, 255, 255, 0.3);
}
    }
  }
}
}
 `;


const NewProgressPoints = styled.div`
width: 70%;
margin: auto 39px;
border-radius: 20px;
`;

const NewRewardLevel = styled.div`

width: calc(98%/3);
display: inline-block;
`;

const RealProgressPoint = styled.div`
 width: 100%
`;



const MainBarLineProgressPoint = styled.div`
background-color: #F3F3F5;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
border-radius: 20px;
height: 38px;
`;

const MainBarLine = styled.div`
 width: 62%;
 display: flex;
 margin: auto;
`;
const BodyBarLine = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
`;
              
const TitleContent = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
padding-bottom: 6px;
 .Bronze_cls {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #A57576
 }
 .Sliver_cls {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center
  color: #B9C1C6;
 }
 .Gold_cls {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px
  text-align: center;
  color: #DCC34C;
 }
 .Finish_cls {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #0D4270;
  position: relative;
  left: 68px;
 }
`;
const BarContent = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
 height: 38px;
 background: #F3F3F5;
border-radius: 18px;
.main_images {
  display: flex;
 justify-content: space-between;
  width: 100%;
 .image_cls {
  &.flex-basis-30 {
    flex-basis: 5% !important;
  }
  >img {
    width: 38.49px;
    height: 52px;
  }
}
}
`;
const ColorBar = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
 height: 38px;
 background-color: ${({color}) => color && color};
 width: ${({width}) => width && `${width}%`};
border-radius: 18px;
.image_cls {
  &.flex-basis-30 {
    flex-basis: 5% !important;
  }
  >img {
    width: 38.49px;
    height: 52px;
  }
}
`;
const PointContent = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
padding-top: 15px;
padding-left: 15px
.first_cls {
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #A57576;
position: relative;
top: 15px;
right: 10px;
}
.second_cls {
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #B9C1C6;
position: relative;
top: 15px;
left: 10px;
}
.third_cls {
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #DCC34C;
position: relative;
top: 15px;
left: 17px;
}
.four_cls {
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #0D4270;
position: relative;
top: 15px;
left: 79px;
}
`;
const MainProgressBar = styled.div`
 width: 100%
`;

const ImageTag = styled.div`
 width: 108px;
 height: 108px;
 padding-top: 29px;
 div{
  >img {
      width: 100%;
      height: auto;
  }

 }
  
  >img {
      width: 100%;
      height: auto;
  }
`;
const BgData = styled.div`
width: 108px;
height: 108px;
background: ${({background }) => background ? background: background};
opacity: 0.2;
margin-top: -26px;
border-radius: 50%;
`;

const BronzeImg = styled.div`
width: ${({width}) => width ?  width: width};
height: ${({height}) => height ? height : height};
height: 124px;
position: absolute;
position: relative;
padding-left: ${({left}) => left ?  left : left};
top: ${({top}) => top ?  top : top};
div{
  >img {
 width: 60px;
height: 80px;
height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
  }
}
>img {
 width: 60px;
height: 80px;
height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
`;
const SilverImg = styled.div`
width: ${({width}) => width ?  width: width};
  height: ${({height}) => height ? height : height};
  height: 124px;
  position: absolute;
  position: relative;
  padding-left: ${({left}) => left ?  left : left};
  top: ${({top}) => top ?  top : top};
  div{
    >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
  }

  }
  >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
  }
`;
const GoldImg = styled.div`
position: absolute;
position: relative;
width: ${({width}) => width ?  width: width};
height: ${({height}) => height ? height : height};
height: 124px;
padding-left: ${({left}) => left ?  left : left};
  top: ${({top}) => top ?  top : top};
  div{
    >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
  }

  }
  >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '52px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '38px'};
  }
`;

const FlagImg = styled.div`
position: relative;
width: ${({width}) => width ?  width: width};
height: ${({height}) => height ? height : height};
height: 124px;
padding-left: ${({left}) => left ?  left : left};
  top: ${({top}) => top ?  top : top};
  div{
    >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '58px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '58px'};
  }

  }
  >img {
    width: 60px;
    height: 80px;
    height: ${({imageWidth}) => imageWidth ? '80px' : '58px'};
    width: ${({imageWidth}) => imageWidth ?  '60px': '58px'};
  }
`;

const FirstClass = styled.div`
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #A57576;
padding-left: ${({paddingLeft}) => paddingLeft ? paddingLeft : paddingLeft};
margin-top: ${({marginTop}) => marginTop ? marginTop : marginTop};
margin-left: ${({marginLeft}) => marginLeft ? marginLeft : marginLeft};
`;
const SecondClass = styled.div`
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #B9C1C6;
padding-left: ${({paddingLeft}) => paddingLeft ? paddingLeft : paddingLeft};
margin-top: ${({marginTop}) => marginTop ? marginTop : marginTop};
margin-left: ${({marginLeft}) => marginLeft ? marginLeft : marginLeft};
`;
const ThirdClass = styled.div`
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #DCC34C;
padding-left: ${({paddingLeft}) => paddingLeft ? paddingLeft : paddingLeft};
margin-left: ${({marginLeft}) => marginLeft ? marginLeft : marginLeft};
margin-top: ${({marginTop}) => marginTop ? marginTop : marginTop};
`;
const FourClass = styled.div`
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #0D4270;
margin-top: 30px; // 15px;

`;
 
const Bronze = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #A57576;
  padding-bottom: ${({paddingBottom}) => paddingBottom ?  paddingBottom : '7px'};
  margin-left: ${({paddingLeft}) => paddingLeft ?  paddingLeft : paddingLeft};
`;
const Silver = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #B9C1C6;
  padding-bottom: ${({paddingBottom}) => paddingBottom ?  paddingBottom : '7px'};
  padding-left: ${({paddingLeft}) => paddingLeft ?  paddingLeft : paddingLeft};
`;
const Gold = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #DCC34C;
  padding-bottom: ${({paddingBottom}) => paddingBottom ?  paddingBottom : '7px'};
  padding-left: ${({paddingLeft}) => paddingLeft ?  paddingLeft : paddingLeft};
`;
const Finished = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #0D4270;
  padding-bottom: 7px;
`;

const BarWrapper = styled.div`
width: 10%;
display: flex;
`;

const NewProgressCardV2 = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  background-color: #ffffff;
  margin: 25px 0px;
  @media (max-width: 330px) {
    height: 220px;
  }
  .padding {
    padding: 15px;
  }
`;


export { CategoryContainer, RewardsCategoriesTitle, BarContainer, ProgressBar, ProgressContent, 
  ProgressPoints, RewardText, ArrowContainer, ArrowReward, RewardLevel, Flexbox, ProgressCard,Border, ProgressTitle, TitleFlex, Layout, RewardsDashboardContainer, MainContainer, SideSection, CategoryCardV2, ImageContainer, PointsCard, MyHistory, RightArrow,NewProgressCard,NewProgressContent,NewProgressPoints,NewRewardLevel,MainBarLineProgressPoint,MainBarLine,BodyBarLine,BarContent,TitleContent,PointContent,ColorBar,RealProgressPoint,MainProgressBar,ImageBar,ImageTag,BgData,BronzeImg,SilverImg,GoldImg,FirstClass,SecondClass,ThirdClass,FourClass,Bronze,Silver,Gold,Finished, BarWrapper, FlagImg, NewProgressCardV2};