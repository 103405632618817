import styled from 'styled-components';
const BoldFont = "Rubik-Bold";
const RegularFont = "Rubik-Regular"
import img from '/public/images/Rewards/rewardsBg.png';
const Layout = styled.div`
  width: 100%;
  @media (max-width: 600px){
    padding: 0px;
  }
`;

const TitleFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 10px;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 16px;
      text-transform: uppercase;
    }
    >img{
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
  }
  > div: last-child{
    > div{
      color: #484856;
      font-family: ${BoldFont};
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }
    > div: last-child{
      font-size: 16px;
    }
  }
`;
const ProgressTitle = styled.div`
  font-size: 13px;
  letter-spacing: 0px;
  color: #26262B;
  font-family: ${BoldFont};
  opacity: 0.7;
  margin-top: 5px;
  display: block;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dedcdc;
`;

const ProgressCard = styled.div`
  width: 100%;
  height: 250px;
  box-shadow: 0 0 21px rgba(0,0,0,0.08);
  background-color: #ffffff;
  margin: 10px 0px;
  @media (max-width: 330px) {
    height: 220px;
  }
  .padding {
    padding: 15px;
  }
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > div {
    display: block;
    text-align: center;
  }
`;

const RewardLevel = styled.div`
  width: calc(98%/3);
  display: inline-block;
  float: left;
  background-color: ${({bgColor}) => bgColor && bgColor};
  height: 43px;
  border-top-left-radius:  ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
  border-bottom-left-radius: ${({borderLeftRadius}) => borderLeftRadius && '20px'} ;
  border-top-right-radius:  ${({borderRightRadius}) => borderRightRadius && '20px'} ;
  border-bottom-right-radius: ${({borderRightRadius}) => borderRightRadius && '20px'} ;
  position: relative;
  z-index:  ${({zIndex}) => zIndex && zIndex};
`;

const ArrowContainer = styled.div`
  height: 41px;
  width: 41px;
  transform: rotate(45deg);
  margin-left: -22px; 
  padding: 5px
  z-index: 0;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  float: left;
`;

const ArrowReward = styled.div`
  height: 32px;
  width: 32px;
  background-color: ${({bgColor}) => bgColor && bgColor};
  border-radius: 3px;
`;

const RewardText = styled.div`
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 100%;
  > img {
    width: 36px;
    height: 49px;
    margin: 10px 10px 0 0;
    @media(max-width: 500px){
      width: 20px;
      height: 21px;
      margin: 2px 2px 0 0;
    }
    @media(max-width: 440px){
      width: 16px;
    }
  }
  .text {
    font-family: ${BoldFont};
    text-transform: capitalize;
    font-size: 14px;
    @media(max-width: 440px){
      font-size: 13px;
    }
  }
  .levelPoint {
    width: 100%;
    text-align: right;
    text-transform: lowercase;
    font-family: ${RegularFont}
    margin-right: 2px;
    @media(max-width: 600px){
      text-align: center;
    }
    @media(max-width: 340px){
      font-size: 7px;
    }
  }

  @media (max-width: 1000px){
    font-size: 9px;
  }
  .details{
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 600px){
      flex-direction: column;
    }
  }
`;

const ProgressContent = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  .imageContainer {
    display: inline-block;
    padding-top: 8px;
    float: left;
    width: 8%;
    padding-right: 5px;
    >img {
      width: 56px;
      height: 70px;
    }
    @media(max-width: 600px) {
      width: 15%;
    }
  }
  .buttonWrapper {
    display: inline-block;
    width: 30%;
    text-align: right;
    @media(max-width: 600px) {
      width: 100%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ProgressPoints = styled.div`
  display: inline-block;
  padding-left: ${({padding}) => padding ? '0px' : '13px'};
  width: auto;
  @media(max-width: 600px) {
    margin-left: 3px;
  }
  .points {
    font-size: 30px;
    color: ${({padding}) => padding ? '#FFFFFF' : '#484856'};
    font-family: ${RegularFont};
    font-weight: 800;
    white-space: nowrap;
  }
  .totalPoint {
    font-size: 18px;
    color: ${({padding}) => padding ? '#FFFFFF' : '#484856'};
    font-family: ${RegularFont};
    font-weight: 800;
    text-transform: capitalize;
  }
  .pointContent {
    font-size: 14px;
    letter-spacing: 0px;
    color: #484856;
    font-family: ${RegularFont};
    margin-top: 4px;
    display: flex;
    white-space: nowrap;
  }
  .inline {
    display: inline-block;
    >span{
      font-family: ${BoldFont};
      text-transform: capitalize;
    }
  }
`;

const ProgressBar = styled.div`
  height: 8px;
  border-radius: 4px;
  background-color: #edecec;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  .progrss {
    height: 8px;
    border-radius: 4px;
    background-color: ${({color}) => color && color};
    width: ${({width}) => width && `${width}%`};
  }
`;

const BarContainer = styled.div`
  height: 100%;
  width: ${({ customAlign}) => customAlign ? '83%': '91%'};
  margin-left: ${({ customAlign}) => customAlign ? '14%': '6%'};
  .flexBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    @media(max-width: 440px){
      width: auto;
    }
  }
  @media(max-width: 700px) {
    width: ${({ customAlign}) => customAlign ? '82%': '91%'};
    margin-left: ${({ customAlign}) => customAlign ? '16%': '6%'};
  }
`;

const RewardsCategoriesTitle = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  font-family: ${BoldFont};
  padding-top: 20px;
  margin-bottom: 15px;
  width: 100%;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media(max-width: 600px) {
    margin: 0px;
    display: block;
  }
`;

const RewardsDashboardContainer = styled.div`
  width: 100%;
  padding: 10px;
`;
const MainContainer = styled.div`
  float: left;
  padding: 5px 10px 5px 0px;
  @media(max-width: 1200px){
    width: 100%;
  }
`;
const SideSection = styled.div`
  width: calc(23% - 10px);
  float: left;
  margin-right: 10px
  @media(max-width: 1200px){
    width: 60%;
  }
  @media(max-width: 500px){
    width:100%;
  }
`;
const CategoryCardV2 = styled.div`
  width: calc((96% / 4) - 7px);
  border-radius: 6px;
  display: flex;
  margin: 10px 7px 10px 0;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  float: left;
  flex-wrap: wrap;
  &:last-child{
    margin: 10px 0;
  }
  .bg-opacity {
    position: absolute;
    height: 180px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    border-radius: 6px;
  }
  .information-icon{
    position: absolute;
    top: 15px;
    right: 22px;
    >img{
     width: 26px;
     height: 26px;
    }
  }
  .textContainer {
    position: absolute;
    top: 55px;
    left: 10px;
    padding: 10px;
    float: left;
    width: 100%;
    .rewardName {
      font-size: 18px;
      letter-spacing: 0px;
      color: #FFFFFF;
      font-family: ${RegularFont};
      margin-bottom: 20px;
    }
    .available {
      font-size: 13px;
      letter-spacing: 0px;
      color: #FFFFFF;
      font-family: ${RegularFont};
      margin-top: -3px;
      > div {
        display: inline-block;
      }
    }
  }
  .pointsCalculator{
    width: 100%;
    background: #185873;
    box-shadow: 0px 3.28141px 6.56281px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 10px 0;
    .pointsHeader{
      padding: 10px 7px;
      font-size: 9px;
      font-family: ${BoldFont};
      line-height: 8px;
      color: #FFFFFF;
    }
    .pointsEarned{
      width: 100%;
      background: #0D4270;
      box-shadow: 0px 3.28141px 6.56281px rgba(0, 0, 0, 0.08);
      padding: 6px;
      border-radius: 6px;
      .earnedText{
        padding: 7px;
        font-family: 'Rubik';
        font-style: normal;
         font-weight: 500;
           font-size: 10px;
           line-height: 12px;
           color: #FFFFFF;
      }
    }
  }
  .bonusPoints{
    width: 100%;
    background: #0D4270;
    box-shadow: rgb(0 0 0 / 8%) 0px 3.28141px 6.56281px;
    padding: 6px;
    border-radius: 6px;
    .bonusHeader{
      padding: 7px;
      font-size: 11px;
      font-family: ${BoldFont};
      line-height: 8px;
      color: #FFFFFF;
    }
  }
  @media (max-width: 1200px){
    width: calc((100% / 3) - 7px);
    &:last-child{
      width: calc(100% / 3);
    }
  }
  @media (max-width: 1000px){
    width: calc((100% / 2) - 7px);
    &:last-child{
      width: calc(100% / 2);
    }
  }
  @media (max-width: 600px){
    width: 100%;
    &:last-child{
      width: 100%;
    }
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  display: inline-block;
  position:relative
  height: 180px;
  border-radius: 6px;
  > img {
    height: 180px;
    border-radius: 6px;
    width: 100%;
  }
`;
const PointsCard = styled.div`
  padding: 5px;
  min-height: 70px;
  background: #FFFFFF;
  margin: 0px 0px 6px;
  border-radius: 4px;
  box-shadow: 0px 3.93769px 6.56281px rgba(0, 0, 0, 0.08);
  &:last-child{
    margin: 0;
  }
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3px;
    >div{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    color: #0D4270;
    }
   .image_class {
     width: auto;
     height: 22px;
    >img{
      width: 22px;
      height:  22px;
    }
   }

    >img{
    }
  }
  >div:last-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px;
    >div: first-child{
      >div{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #0D4270;
      }
      >div: first-child{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #0D4270;
        
      }
      >span{
        font-size: 10px;
        color: #4798D9;
        cursor: pointer;
        border-bottom: 1px solid #4798D9;
      }
    }
    >div: last-child{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 25px;
      text-align: right;
      color: #0D4270;
      >span{
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    font-family: Rubik;
     color: #0D4270;
      }
    }
  }
`;
const MyHistory = styled.div`
  width: 100%;
  background: #FFFFFF;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #1E76AB;
    padding: 10px;
    border-radius: 6px 6px 0 0;
    >div{
      color: #FFFFFF;
      font-family: ${BoldFont};
      font-size: 14px;
      text-transform: uppercase;
    }
    >img{
      width: 20px;
      height: 25px;
      margin-right: 8px;
    }
  }
  > div: last-child{
    >div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      > div: first-child{
        display: flex;
        align-items: center;
        >img{
          width: 37px;
          height: 50px;
          margin-right: 8px;
        }
        >div{
          .yearText{
            color: #282C37;
            font-family: ${RegularFont};
            text-transform: capitalize;
            font-size: 17px;
          }
          .year{
            color: #282C37;
            font-family: ${BoldFont};
            font-size: 16px;
            opacity: 0.5;
          }
        }
      }
      > div: last-child{
        color: #000000;
        font-family: ${BoldFont};
        font-size: 16px;
      }
    }
  }
`;
const RightArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 21px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 22px solid #DCC34C;
  position: absolute;
  top: 0;
  right: -22px;
`;

const CardContainer = styled.div`
display: flex;
flex-wrap: wrap;
width: 100%;
`;
const RealCard = styled.div`
    background: white;
    width: 100%
    height: 134px;
    border-radius: 6px;
    .title {
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0D4270;
    padding: 10px 10px 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    }
   .main_Class
   {
   display: flex;
   { 
    .image_class {
    width: 113px;
    height: 98px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .content_class {
    padding-top: 13px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: calc(100% - 98px );
      .point_data {
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: ${ ({lang}) => lang=='fr' ? '26px' : '36px'};
      color: #0D4270;
      padding-right: 25px;
    >span {
      font-size: 20px;
      font-family: 'Rubik';
    }
      }
      @media(max-width: 1350px){
        .point_data {
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          color: #0D4270;
          padding-right: 15px;
        >span {
          font-size: 20px;
          font-family: 'Rubik';
        }
          }
      }
      .rest_data {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
       font-size: 11.2px;
       text-align: center;
      color: #FD7175;
      padding-right: 35px;
      margin-bottom: 20px;
  }
  @media(max-width: 1350px){
    .rest_data {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
     font-size: 11.2px;
     text-align: center;
    color: #FD7175;
    padding-right: 25px;
    margin-bottom: 20px;
}
  }
      }
    }
  }
`;

const ImageRender = styled.div`
  width: ${({ width}) =>  width ?   width :  width};
  height: 98px;

  div{
    > img {
      width: 100%;
      height: 100%;
    }

  }
    > img {
      width: 100%;
      height: 100%;
    }
`;


export { CategoryContainer, RewardsCategoriesTitle, BarContainer, ProgressBar, ProgressContent, ProgressPoints, RewardText, ArrowContainer, ArrowReward, RewardLevel, Flexbox, ProgressCard, Border, ProgressTitle, TitleFlex, Layout, RewardsDashboardContainer, MainContainer, SideSection, CategoryCardV2, ImageContainer, PointsCard, MyHistory, RightArrow,CardContainer,RealCard,ImageRender};